export const config = {
    url: {
        CHAT: `/api/chat/`,
        USERS: `/api/users/`,
        SCENARIOS: `/api/scenarios/`,
        COURSES: `/api/courses/`,
        ATTEMPTS: `/api/attempts/`,
        FEEDBACK: `/api/feedback/`,
        GENERATE: `/api/generate/`,
    },
};
