import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { BarChartLine, Book, CaretLeftFill, CaretRightFill, People } from 'react-bootstrap-icons';
import IconButton from 'src/ui/IconButton';
import SideBarBottom from 'src/layout/SideBarBottom';
import { useStyleContext } from 'src/contexts/StyleContext';
import useAuthContext from 'src/hooks/useAuthContext';
import { iconWrapperStyle, itemWrapperStyle, textStyle } from 'src/styles/sideBarItemStyle';
import icon from 'src/assets/img/icon.png';
import logoHorizontal from 'src/assets/img/logo-horizontal.png';

const StyledSideBar = styled.aside`
    position: sticky;
    top: 0;
    padding-top: ${({ theme }) => theme.spacing.m};
    height: ${({ theme }) => ` calc(100vh - (${theme.spacing.m}*1))`};
    min-width: 200px;
    max-width: 200px;
    transition: min-width 0.3s ease-in-out;
    &.miniMized {
        min-width: 0;
        transition: min-width 0.3s ease-out 0.2s;
    }
`;
const StyledInnerWrapper = styled.div`
    border-radius: ${({ theme }) => theme.borderRadius.m};
    box-shadow: ${({ theme }) => theme.shadow.s};
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    padding: ${({ theme }) => theme.spacing.m};
`;

const StyledLogoLink = styled(Link)`
    margin-bottom: ${({ theme }) => theme.spacing.s};
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.lightGray}`};
    height: 55px;
`;

const StyledLink = styled(Link)`
    ${itemWrapperStyle}
    text-decoration: none;
`;

const StyledIconWrapper = styled.div`
    ${iconWrapperStyle}
`;
const StyledText = styled.div`
    ${textStyle}
    ${({ theme, isCurrent, highlightColor }) =>
        isCurrent &&
        `font-weight:${theme.fontWeight.l};
       background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 50%,${highlightColor} 50%, ${highlightColor} 100%);
       `};
`;
const StyledIconButton = styled(IconButton)`
    background-color: ${({ theme }) => theme.colors.primaryLight2};
    box-shadow: ${({ theme }) => `9px 0px 12px -5px ${theme.colors.gray}`};
    position: absolute;
    top: 40px;
    right: -10px;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
`;

const SideBar = () => {
    const theme = useTheme();
    const location = useLocation();
    const { isSidebarMinimized, setIsSidebarMinimized } = useStyleContext();
    const { currentUser } = useAuthContext();

    const links_instructor = [
        {
            label: 'Dashboard',
            path: '/',
            icon: <BarChartLine color={theme.colors.primary} />,
            highLightColor: `${theme.colors.primaryLight}`,
        },
        {
            label: 'Courses',
            path: '/courses',
            icon: <People color={theme.colors.secondary} />,
            highLightColor: `${theme.colors.secondaryLight}`,
        },
        {
            label: 'Scenarios',
            path: '/scenarios',
            icon: <Book color={theme.colors.success} />,
            highLightColor: `${theme.colors.successLight}`,
        },
    ];
    const links_student = [
        {
            label: 'Dashboard',
            path: '/',
            icon: <BarChartLine color={theme.colors.primary} />,
            highLightColor: `${theme.colors.primaryLight}`,
        },
        {
            label: 'Courses',
            path: '/courses',
            icon: <People color={theme.colors.secondary} />,
            highLightColor: `${theme.colors.secondaryLight}`,
        },
    ];

    const links = currentUser.isInstructor ? links_instructor : links_student;

    return (
        <StyledSideBar className={isSidebarMinimized && 'miniMized'}>
            <StyledInnerWrapper>
                <StyledLogoLink to={'/'}>
                    {isSidebarMinimized ? (
                        <StyledIconWrapper className={isSidebarMinimized && 'miniMized'}>
                            <img
                                src={icon}
                                alt="icon"
                                style={{ width: '20px', height: '20px' }}
                            />
                        </StyledIconWrapper>
                    ) : (
                        <img
                            src={logoHorizontal}
                            alt="logo"
                            style={{ width: isSidebarMinimized ? '0px' : '140px' }}
                        />
                    )}
                </StyledLogoLink>

                {links.map((link) => (
                    <StyledLink
                        key={link.path}
                        to={link.path}
                    >
                        <StyledIconWrapper className={isSidebarMinimized && 'miniMized'}>{link.icon}</StyledIconWrapper>
                        <StyledText
                            isCurrent={link.path === '/' ? location.pathname === link.path : location.pathname.startsWith(link.path)}
                            highlightColor={link.highLightColor}
                            className={isSidebarMinimized && 'miniMized'}
                        >
                            {link.label}
                        </StyledText>
                    </StyledLink>
                ))}
                <SideBarBottom isMinimized={isSidebarMinimized} />
                <StyledIconButton onClick={() => setIsSidebarMinimized((prev) => !prev)}>
                    {isSidebarMinimized ? (
                        <CaretRightFill
                            color={theme.colors.primary}
                            size={10}
                        />
                    ) : (
                        <CaretLeftFill
                            color={theme.colors.primary}
                            size={10}
                        />
                    )}
                </StyledIconButton>
            </StyledInnerWrapper>
        </StyledSideBar>
    );
};

export default SideBar;
