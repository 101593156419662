import { Routes, Route, Navigate } from 'react-router-dom';

import Layout from 'src/layout/Layout';
import DashboardPage from 'src/pages/DashboardPage';
import ScenarioListsPage from 'src/pages/scenarios/ScenarioListsPage';
import ScenarioCreatePage from './pages/scenarios/ScenarioCreatePage';
import ScenarioEditPage from './pages/scenarios/ScenarioEditPage';
import NotFoundPage from './pages/NotFoundPage';
import CourseListsPage from './pages/courses/CourseListsPage';
import AttemptDetailPage from './pages/attempts/AttemptDetailPage';
import CourseDetailPage from './pages/courses/CourseDetailPage';
import ScenarioDetailPage from 'src/pages/scenarios/ScenarioDetailPage';
import ScenarioAttemptPage from './pages/attempts/ScenarioAttemptPage';
import CourseCreatePage from './pages/courses/CourseCreatePage';
import CourseScenarioDetailPage from './pages/courses/CourseScenarioDetailPage';
import CourseEditPage from './pages/courses/CourseEditPage';

import CallbackPage from './pages/CallbackPage';
import SignOutPage from './pages/SignOutPage';
import { AuthenticationGuard } from './lib/AuthenticationGuard';

function App() {
    return (
        <Routes>
            {/* Dashboard - Both */}
            <Route
                path="/"
                exact
                element={
                    <Layout title="Dashboard">
                        <AuthenticationGuard component={DashboardPage} />
                    </Layout>
                }
            />

            {/* Course Lists Page - Both */}
            <Route
                path="/courses"
                element={
                    <Layout title="Course Lists">
                        <AuthenticationGuard component={CourseListsPage} />
                    </Layout>
                }
            />
            {/* Course Detail Page - Both */}
            <Route
                path="/courses/:courseId"
                element={
                    <Layout title="Course Detail">
                        <AuthenticationGuard component={CourseDetailPage} />
                    </Layout>
                }
            />

            {/* Scenario Detail Page for Course - Both */}
            <Route
                path="/courses/:courseId/scenarios/:scenarioId"
                element={
                    <Layout title="Scenario Detail">
                        <AuthenticationGuard component={CourseScenarioDetailPage} />
                    </Layout>
                }
            />

            {/* Attempt Submission Page - Instructor and Student have different path */}
            <Route
                path="/scenarios/:scenarioId/attempt"
                element={
                    <Layout title="Scenario Attempt">
                        <AuthenticationGuard component={ScenarioAttemptPage} />
                    </Layout>
                }
            />

            <Route
                path="/courses/:courseId/scenarios/:scenarioId/attempt"
                element={
                    <Layout title="Scenario Attempt">
                        <AuthenticationGuard component={ScenarioAttemptPage} />
                    </Layout>
                }
            />

            {/* Attempt Detail Page - Both */}
            <Route
                path="/attempts/:attemptId"
                element={
                    <Layout title="Attempts">
                        <AuthenticationGuard component={AttemptDetailPage} />
                    </Layout>
                }
            />

            {/* Course Create Page - Instructor Only */}
            <Route
                path="/courses/create"
                element={
                    <Layout title="Create Course">
                        <AuthenticationGuard component={CourseCreatePage} />
                    </Layout>
                }
            />

            {/* Course Edit Page - Instructor Only */}
            <Route
                path="/courses/:courseId/edit"
                element={
                    <Layout title="Edit Course">
                        <AuthenticationGuard component={CourseEditPage} />
                    </Layout>
                }
            />

            {/* Scenario Lists Page - Instructor Only */}
            <Route
                path="/scenarios"
                element={
                    <Layout title="Scenario Lists">
                        <AuthenticationGuard component={ScenarioListsPage} />
                    </Layout>
                }
            />

            {/* Scenario Detail Page - Instructor Only */}
            <Route
                path="/scenarios/:scenarioId"
                element={
                    <Layout title="Scenario Detail">
                        <AuthenticationGuard component={ScenarioDetailPage} />
                    </Layout>
                }
            />

            {/* Scenario Create Page - Instructor Only */}
            <Route
                path="/scenarios/create"
                element={
                    <Layout title="Scenario Create">
                        <AuthenticationGuard component={ScenarioCreatePage} />
                    </Layout>
                }
            />

            {/* Scenario Edit Page - Instructor Only */}
            <Route
                path="/scenarios/:scenarioId/edit"
                element={
                    <Layout title="Scenario Edit">
                        <AuthenticationGuard component={ScenarioEditPage} />
                    </Layout>
                }
            />

            {/* Not found - Both */}
            <Route
                path="/not-found"
                element={
                    <Layout title="Not Found">
                        <AuthenticationGuard component={NotFoundPage} />
                    </Layout>
                }
            />

            <Route
                path="/callback"
                element={
                    <Layout>
                        <AuthenticationGuard component={CallbackPage} />
                    </Layout>
                }
            />
            <Route
                path="/sign-out"
                element={<SignOutPage />}
            />

            {/* Test page to throw an error to test sentry */}
            <Route
                path="/sentry-test"
                element={
                    <button
                        onClick={() => {
                            throw new Error('This is my error');
                        }}
                    >
                        Break the world
                    </button>
                }
            />

            <Route
                path="*"
                element={<Navigate to="/not-found" />}
            />
        </Routes>
    );
}

export default App;
